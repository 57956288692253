.edxops-today {
  padding: $edxops-today-padding ($edxops-today-padding / 2);
  margin-bottom: $edxops-today-padding;
  color: $edxops-today-color;
  background-color: $edxops-today-bg;
  @include border-radius($border-radius-lg);

  @include media-breakpoint-up(sm) {
    padding: ($edxops-today-padding * 2) $edxops-today-padding;
  }
}

.edxops-today-fluid {
  padding-right: 0;
  padding-left: 0;
  @include border-radius(0);
}
